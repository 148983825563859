.sectionContact {
  margin-top: 18rem;
}

.contact {
  text-align: center;
  background: rgb(219, 217, 217);
  padding-bottom: 4rem;
}

.contact .boxContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.contact .boxContainer .box {
  height: 18rem;
  margin: 1.5rem 1rem;
  width: 30rem;
  overflow: hidden;
  position: relative;
  background: white;
  border-radius: 10px;
  padding: 35px 50px;
  -webkit-box-shadow: 0px 0px 10px 0px rgba(66, 68, 90, 1);
  -moz-box-shadow: 0px 0px 10px 0px rgba(66, 68, 90, 1);
  box-shadow: 0px 0px 10px 0px rgba(66, 68, 90, 1);
}

.contact .boxContainer .box svg {
  font-size: 2.5rem;
  color: var(--main_background);
  margin: 0 45%;
}

.contact .boxContainer .box h3 {
  font-size: 1.5rem;
  position: relative;
  top: -70px;
}

.contact .boxContainer .box p {
  font-size: 1.5rem;
  position: relative;
  top: -50px;
  font-weight: bold;
  color: var(--main_background);
}

@media (max-width: 600px) {
  .contact .boxContainer .box {
    height: 15rem;
    margin: 1rem 0.7rem;
    width: 25rem;
  }

  .contact .boxContainer .box svg {
    font-size: 2.2rem;
  }

  .contact .boxContainer .box h3 {
    font-size: 1.2rem;
    top: -100px;
  }

  .contact .boxContainer .box p {
    font-size: 1.5rem;
    top: -80px;
  }
}

@media (max-width: 600px) {
  .contact .boxContainer .box {
    height: 15rem;
    margin: 1rem 0.7rem;
    width: 22rem;
  }
}
