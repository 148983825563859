* {
  margin: 0;
  padding: 0;
  font-family: "Encode Sans Expanded", sans-serif;
  transition: all 0.5s ease-in-out;

  --main_background: #193366;
  --second_background: #2d5bb9;
}

html {
  scroll-behavior: smooth;
}

body {
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("./components/Header/Images/banner.jpg") no-repeat fixed center;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
}

.heading {
  display: inline-block;
  color: #333;
  padding: 4rem 0 3rem;
  font-size: 4rem;
}

@media (max-width: 600px) {
  .heading {
    font-size: 3rem;
  }
}
