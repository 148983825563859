.sectionAbout {
  background: #999;
}

.about {
  /*clip-path: polygon(0 0, 100% 0, 100% 85%, 50% 100%, 0 85%);*/
  background-color: #fff;
  padding-bottom: 3rem;
  padding-top: 3rem;
}

.about .row {
  min-height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  padding-top: 0rem;
}

.about .row .image {
  flex: 1 1 40rem;
  padding: 2rem;
}

.about .row .image img {
  height: 100%;
  width: 100%;
}

.about .row .content {
  flex: 1 1 40rem;
  padding: 0 2rem 2rem 2rem;
}

.about .row .content p {
  color: #666;
  font-size: 1.4rem;
  padding: 1rem 0;
  transition: transform 500ms ease;
}

.about .row .content ul li {
  color: #666;
  font-size: 1.4rem;
  transition: transform 500ms ease;
  margin-left: 20px;
}

@media (max-width: 600px) {
  .about {
    padding-bottom: 1rem;
    padding-top: 1rem;
  }

  .about .row {
    min-height: 80vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    padding-top: 0rem;
  }

  .about .row .content p {
    font-size: 1.2rem;
  }

  .about .row .content ul li {
    font-size: 1.2rem;
  }
}

@media (max-width: 600px) {
  .about .row .content p {
    font-size: 1rem;
  }

  .about .row .content ul li {
    font-size: 1rem;
  }
}
