.service {
  min-height: 100vh;
  text-align: center;
  background-color: #eee;
  padding-bottom: 6rem;
}

.service .boxContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.service .boxContainer .box {
  width: 37rem;
  height: 25rem;
  margin: 1rem;
  overflow: hidden;
  position: relative;
}

.service .boxContainer .box img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.service .boxContainer .box .title {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(0, 0, 0, 0.35);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
  color: #ffffff;
}

.service .boxContainer .box .info {
  height: 90%;
  width: 90%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0);
  background: rgba(222, 222, 222, 0.9);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  transition: transform 1s ease-in-out;
}

.service .boxContainer .box:hover .info {
  transform: translate(-50%, -50%) scale(1);
  transition: transform 1s ease-in-out;
}

.service .boxContainer .box .info h3 {
  font-size: 2.3rem;
  color: #333;
}

.service .boxContainer .box .info p {
  font-size: 1.2rem;
  color: #666;
  padding: 1rem 2rem;
}

.container,
.column {
  color: #fff;
  text-align: left;
}

.container h1,
.column h1 {
  font-size: 2.1rem;
  padding-bottom: 1rem;
}

.container h2,
.container p,
.container ul {
  padding-bottom: 1rem;
}

.container ul li {
  margin-left: 1rem;
  padding: 0.1rem 0 0.1rem 0;
}

.column h2 {
  text-align: center;
  font-size: 1.3rem;
}

.column p {
  font-size: 1rem;
  padding-bottom: 0.5rem;
}

.column ul {
  padding-bottom: 1rem;
}

.column ul li {
  color: #fff;
}

.serviceBox {
  border-radius: 25px;
  padding-top: 1rem;
}

.containerBox {
  -webkit-column-count: 2;
  -moz-column-count: 2;
  column-count: 2;
  padding: 0.5rem 0.5rem;
}

.ofertBox {
  font-size: 1rem;
  padding: 0 2rem;
}

.ofertBox2 {
  padding: 0 2rem;
  font-size: 1rem;
  display: inline-block;
}

@media (max-width: 900px) {
  .containerBox {
    -webkit-column-count: 1;
    -moz-column-count: 1;
    column-count: 1;
    -webkit-column-width: 95%;
    -moz-column-width: 95%;
    column-width: 95%;
    padding: 0.5rem 0.5rem;
  }

  .ofertBox {
    font-size: 0.7rem;
  }

  .ofertBox2 {
    font-size: 0.7rem;
  }

  .column h2 {
    text-align: center;
    font-size: 1rem;
  }

  .column p {
    font-size: 0.7rem;
  }

  .serviceBox {
    padding-top: 0.5rem;
  }

  .column ul {
    padding-bottom: 0.4rem;
  }
}

@media (max-width: 711px) {
  .service .boxContainer .box {
    width: 30rem;
    height: 20rem;
    margin: 0.5rem;
  }
}

@media (max-width: 550px) {
  .service .boxContainer .box .title {
    font-size: 2rem;
  }

  .service .boxContainer .box {
    width: 25rem;
    height: 18rem;
    margin: 0.5rem;
  }

  .service .boxContainer .box .info h3 {
    font-size: 2rem;
  }

  .service .boxContainer .box .info p {
    font-size: 1rem;
    padding: 0.5rem 1rem;
  }
}

@media (max-width: 400px) {
  .service .boxContainer .box {
    width: 22rem;
    height: 15rem;
    margin: 0.5rem;
  }
}
