*,
*:before,
*:after {
  box-sizing: border-box;
}

body {
  font-family: poppins;
  margin: 0;
  display: grid;
  font-size: 14px;
}
header p {
  padding: 50px;
  text-align: center;
  color: #bebebe;
  text-transform: uppercase;
  font-size: 65px;
  font-weight: 700;
}
.footer {
  display: flex;
  flex-flow: row wrap;
  padding: 50px;
  color: #fff;
  background-color: var(--main_background);
}

.footer > * {
  flex: 1 100%;
}

.l-footer {
  margin-right: 1.25em;
  margin-bottom: 2em;
}

.l-footer img {
  height: 70px;
}

h2 {
  font-weight: 400;
  font-size: 15px;
}

.footer ul {
  list-style: none;
  padding-left: 0;
}

.footer li {
  line-height: 2em;
}

.footer a {
  text-decoration: none;
}

.r-footer {
  display: flex;
  flex-flow: row wrap;
}

.r-footer > * {
  flex: 1 50%;
  margin-right: 1.25em;
}

.box a {
  color: #999;
}

.box a:hover {
  color: #777;
}

.box li {
  color: #999;
}

.h-box {
  column-count: 2;
  column-gap: 1.25em;
}

.b-footer {
  text-align: center;
  color: #999;
  padding-top: 50px;
}

.l-footer p {
  padding-right: 20%;
  color: #999;
}

@media screen and (min-width: 600px) {
  .r-footer > * {
    flex: 1;
  }

  .features {
    flex-grow: 2;
  }

  .l-footer {
    flex: 1 0px;
  }

  .r-footer {
    flex: 2 0px;
  }
}
