.btn_clasic {
  border-radius: 50px;
  background: var(--main_background);
  white-space: nowrap;
  padding: 10px 22px;
  color: #fff;
  font-size: 20px;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  -webkit-box-shadow: 0px 0px 10px 0px rgba(66, 68, 90, 1);
  -moz-box-shadow: 0px 0px 10px 0px rgba(66, 68, 90, 1);
  box-shadow: 0px 0px 10px 0px rgba(66, 68, 90, 1);
}

.btn_clasic:hover {
  background: var(--second_background);
}

@media (max-width: 550px) {
  .btn_clasic {
    font-size: 15px;
  }
}
