.btn-container {
  width: 275px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--bg);
  border-radius: 60px;
}
.btn-container:nth-child(1) {
  --btn-bg: var(--main_background);
  --bg: var(--second_background);
}
a {
  text-decoration: none;
}
.btn {
  --width: 275px;
  --height: 60px;
  border: 0;
  position: relative;
  min-width: var(--width);
  min-height: var(--height);
  border-radius: var(--height);
  color: #fff;
  font-family: "Montserrat";
  font-weight: bold;
  font-size: 1.5rem;
  background: rgba(0, 0, 0, 0.3);
  cursor: pointer;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 1rem;
}
.btn .text,
.btn .icon-container {
  position: relative;
  z-index: 2;
}
.btn .icon-container {
  --icon-size: 25px;
  position: relative;
  width: var(--icon-size);
  height: var(--icon-size);
  margin-left: 8px;
  transition: transform 500ms ease;
}
.btn .icon-container .icon {
  position: absolute;
  left: 0;
  top: 0;
  width: var(--icon-size);
  height: var(--icon-size);
  transition: transform 500ms ease, opacity 250ms ease;
}
.btn .icon-container .icon--left {
  transform: translateX(-200%);
  opacity: 0;
}
.btn .icon-container .icon svg {
  width: 100%;
  height: 100%;
  fill: #fff;
}
.btn::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: var(--btn-bg);
  border-radius: var(--height);
  z-index: 1;
  transition: transform 500ms ease;
}
.btn:hover::after {
  transform: translateX(78%);
}
.btn:hover .icon-container {
  transform: translateX(125%);
}
.btn:hover .icon-container .icon--left {
  transform: translateX(0);
  opacity: 1;
}
.icon--left svg {
  position: absolute;
  right: 17px;
}

.btn:hover .icon-container .icon--right {
  transform: translateX(200%);
  opacity: 0;
}
.support {
  position: absolute;
  right: 10px;
  bottom: 10px;
  padding: 10px;
  display: flex;
}
.support a {
  margin: 0 10px;
  color: #fff;
  font-size: 1.8rem;
  backface-visibility: hidden;
  transition: all 150ms ease;
}
.support a:hover {
  transform: scale(1.1);
}
