.sectionHome {
  width: 100%;
}

.home {
  min-height: 100vh;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
}

.home h1 {
  font-size: 5rem;
  color: #ffffff;
  text-align: center;
  text-shadow: 0 0.3rem 0.5rem #000;
  padding: 0 1rem;
  text-transform: uppercase;
}

.home a button {
  box-shadow: 0 0.3rem 0.5rem #000;
  color: #ffffff;
}

@media (max-width: 711px) {
  .home h1 {
    font-size: 4rem;
  }
}

@media (max-width: 600px) {
  .home h1 {
    font-size: 3rem;
  }
}

@media (max-width: 400px) {
  .home h1 {
    font-size: 2.5rem;
  }
}
